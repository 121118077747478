<template>
    <div class="Feamization">
        <div class="countdown">
            <div class="clearfix" style="width:100%">
                <div class="div_titles fl">可行优化</div>
                <div class="count_span fl" v-show="countNum==1">
                    <span>{{count.name}}倒计时：</span>
                    <span class="timer_s">{{timer}}</span>
                </div>
                <!-- <button class="count_but fr" @click="submit()">提交</button> -->
            </div>
        </div>
        <div class="Feamiza_div">
        <div class="ul_divsss">
            <div class="ke_ul">
                <div class="ke_ul_left">
                    <p class="title_divp">投票选择</p>
                    <div class="lable_div">
                        <strong class="lable_div_strong">最赞赏的</strong>
                        <button class="lable_div_butt" @click="toupiao(fp3.top.id)">
                            <span v-show="fp3.top.id==0">
                                <i class="iconfonts icons-dianzan_kuai"></i>
                                <span>投票</span>
                            </span>
                            <span v-show="fp3.top.id!=0">
                                已投票
                            </span>
                        </button>
                        <span class="lable_div_span" v-show="fp3.top.id!=0">{{fp3.top.name}}</span>
                    </div>
                </div>
                <div class="ke_ul_right clearfix">
                    <div class="fl ke_ul_r_div">
                        <p class="title_divp">我们：最明确的</p>
                        <div class="content_dov">
                            <div class="content_i" v-show="conte.zmqd==''">
                                 <i class="iconfonts icons-jiahao"  @click="shuru(1)"></i>
                            </div>
                            <div class="content_daa" v-show="conte.zmqd!=''">
                                {{conte.zmqd}}
                            </div>
                        </div>
                    </div>
                    <div class="fr ke_ul_r_div">
                        <p class="title_divp">我们：最新想到的</p>
                        <div class="content_dov">
                            <div class="content_i" v-show="conte.zxxd==''">
                                 <i class="iconfonts icons-jiahao"  @click="shuru(2)"></i>
                            </div>
                            <div class="content_daa"  v-show="conte.zxxd!=''">
                                {{conte.zxxd}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="ke_top clearfix">
                <div class="fl ke_top_left">
                    <p class="lable_p">排行榜</p>
                    <ul class="left_uls">
                        <li>
                            <div class="li_one hui">
                                排名
                            </div>
                            <div class="li_two lan" style="color:#fff">
                                <span>最赞赏的</span>
                            </div>
                            <div class="li_two hong" style="color:#fff">
                                <span>票数</span>
                            </div>
                        </li>
                        <li v-for="(item,index) in formList.length==0?5:formList" :key="index">
                            <div class="li_one qianhui">
                                <span>{{index+1}}</span>
                            </div>
                            <div class="li_two qianlan">
                                <span>{{item.name}}</span>
                            </div>
                            <div class="li_two qianhong">
                                <span>{{item.score}}</span>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="righttop fr">
                    <div  ref="charts">

                    </div>
                    <p class="clearfix right_t_p">
                        <span class="fl right_t_one">
                            <i class="iconfonts icons-gupiao"></i>
                        </span>
                        <span class="fl right_text">
                            票数统计
                        </span>
                    </p>
                </div>
            </div>
        <div class="Feam_popup">
            <el-dialog
                title="最赞赏的"
                :visible.sync="dialogVisible"
                width="1000px"
                :before-close="handleClose"
                :show-close="false">
                <i class="el-icon-close closei" @click="closei('ruleForm')"></i>
                <div>
                    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" 
                        label-width="150px" class="demo_dw" size="small" :inline='true' v-show="a_show==true" @submit.native.prevent>
                        <el-form-item label="选择小组" prop="id">
                            <el-select v-model="ruleForm.id" placeholder="请选择小组" @change="teamChose(ruleForm.id)">
                                <el-option
                                    v-for="item in arrList"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="赞赏原因" prop="type">
                            <el-select v-model="ruleForm.type" placeholder="请选择赞赏原因" :disabled='typeTrue'>
                                <el-option
                                    v-for="item in typeList"
                                    :key="item.id"
                                    :label="item.label"
                                    :value="item.id"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <div class="demo_dw_div" v-show="a_show==true">
                            <el-button type="primary" size="mini" @click="subxit('ruleForm')">确定</el-button>
                        </div>
                    </el-form>
                    <el-tabs v-model="activeName" type="border-card">
                        <el-tab-pane :label="item.name" :name="item.name" v-for="(item,index) in arrList" :key="index" @tab-click="handleClick(item)" @submit.native.prevent>
                            <el-form :model="projForm"  label-width="100px" class="demo-ruleForm" size="small" :inline="true">
                                <div class="left_div_t">
                                    <el-form-item label="项目名称" prop="id" style="width:100%">
                                        <el-input v-model="item.title" disabled></el-input>
                                    </el-form-item>
                                </div>
                                <div class="left_div_f">
                                    <el-form-item label="客户" prop="id"  style="width:calc(50% - 10px)">
                                        <div class="colle_div_divs">
                                            <span v-for="(items,indexs1) in item.desc.d2" :key="indexs1">
                                                {{items.value}} <span v-show="indexs1!=item.desc.d2.length - 1">、</span>
                                            </span>
                                        </div>
                                    </el-form-item>
                                    <el-form-item label="买点" prop="id"  style="width:calc(50% - 10px)">
                                        <div class="colle_div_divs">
                                            <span v-for="(items,indexs2) in item.desc.d3" :key="indexs2">
                                                {{items.value}} <span v-show="indexs2!=item.desc.d3.length - 1">、</span>
                                            </span>
                                        </div>
                                    </el-form-item>
                                    <el-form-item label="价格定位" prop="id"  style="width:calc(50% - 10px)">
                                        <div class="colle_div_divs">
                                            <span v-for="(items,indexs3) in item.desc.d4" :key="indexs3">
                                                {{items.value}} <span v-show="indexs3!=item.desc.d4.length - 1">、</span>
                                            </span>
                                        </div>
                                    </el-form-item>
                                    <el-form-item label="吸引我的地方" prop="id"  style="width:calc(50% - 10px)">
                                        <div class="colle_div_divs">
                                            <span v-for="(items,indexs4) in item.desc.d5" :key="indexs4">
                                                {{items.value}} <span v-show="indexs4!=item.desc.d5.length - 1">、</span>
                                            </span>
                                        </div>
                                    </el-form-item>
                                    <el-form-item label="草图描述" prop="id"  style="width:calc(50% - 10px)">
                                        <div class="form_image">
                                            <span class="li_span_img">
                                                <i class="el-icon-zoom-in" @click="dk_Dt(item)"></i>
                                            </span>
                                            <el-carousel>
                                                <el-carousel-item v-for="(items,indexs6) in item.image" :key="indexs6">
                                                    <img :src="items.value" alt="">
                                                </el-carousel-item>
                                            </el-carousel>
                                            <!-- <img :src="item.image" alt=""> -->
                                        </div>
                                    </el-form-item>
                                    <el-form-item label="测试视频" prop="id"  style="width:calc(50% - 10px)">
                                        <div class="form_image">
                                            <video controls>
                                                <source :src="item.video" type="video/mp4">
                                                您的浏览器不支持 video 标签。
                                            </video>
                                        </div>
                                    </el-form-item>
                                </div>
                            </el-form>
                        </el-tab-pane>
                    </el-tabs>
                </div>
                <el-dialog
                    title="查看大图"
                    class="Feam_popup"
                    :visible.sync="innerVisible"
                    append-to-body
                    width="1000px"
                    :before-close="handleClose"
                    :show-close="false"
                >
                    <i class="el-icon-close closei" @click="closeis()"></i>
                    <div class="caotu_img">
                        <el-button-group>
                            <el-button type="primary" icon="el-icon-arrow-left" size="mini" @click="prePage">上一张</el-button>
                            <el-button type="primary" size="mini" @click="nextPage">下一张<i class="el-icon-arrow-right el-icon--right"></i></el-button>
                        </el-button-group>
                        <div style="marginTop: 10px; color: #409EFF">{{ pageNum }} / {{ pageTotalNum }}</div>
                        <img :src="item1.value" v-for="(item1,index1) in imgList" :key="index1" v-show="pageNum == index1 + 1 " alt="">
                    </div>
                </el-dialog>
            </el-dialog>
            <el-dialog
                :title="mytitle"
                :visible.sync="dialogVisibleForm"
                width="600px"
                :before-close="handleClose"
                :show-close="false">
                <i class="el-icon-close closei" @click="closei('Form')"></i>
                <el-form :model="Form" :rules="rules" ref="Form" label-width="100px" class="demo-ruleForm" size="small" @submit.native.prevent>
                    <div class="form_div">
                        <el-form-item :label="mytitle" prop="name">
                            <el-input type="textarea" v-model="Form.name" placeholder="请输入内容" :rows="2" maxlength="20" show-word-limit></el-input>
                        </el-form-item>   
                    </div>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button size="mini" @click="closei('Form')">取消</el-button>
                    <el-button type="primary" size="mini" @click="subxits('Form')">确定</el-button>
                </span>
            </el-dialog>
            </div> 
            </div>
        </div>
    </div>
</template>
<script>
import echarts from 'echarts'
export default {
    data() {
        return {
            timer:'',
            countNum:0,
            imgList:[],
            count:{},
            dialogVisible:false,
            innerVisible:false,
            dialogVisibleForm:false,
            activeName:'',
            mytitle:'',
            ruleForm:{
                id:'',
                type:''
            },
            Form:{
                name:''
            },
            typeTrue:true,
            projForm:{},
            rules:{
                id:[
                    { required: true, message: '请选择小组', trigger: 'change' }
                ],
                type:[
                    { required: true, message: '请选择赞赏原因', trigger: 'change' }
                ],
                name:[
                    { required: true, message: '内容不能为空', trigger: 'blur' }
                ]
            },
            itemList:[],
            valuList:{
                xAxis:[],
                top:[],
                last:[]
            },
            arrList:[],
            typeList:[
                {
                    id:1,
                    label:'创意新颖'
                },
                {
                    id:2,
                    label:'功能实用'
                },
                {
                    id:3,
                    label:'可行性高'
                }
            ],
            fp3:{
                top:{}
            },
            formList:{},
            conte:{
                zmqd:'',
                zxxd:''
            },
            a_show:false,
            pageNum: 1,
            pageTotalNum: 1
        }
    },
    methods: {
        getapp(){
            this.axios.StuCprojectgets8({
                token:this.$store.state.token,
                id:this.$store.state.cla_id
            }).then(res=>{
                if(res.data.code==0){
                    this.formList = res.data.data.data.p1.value
                    this.valuList = res.data.data.data.p2.value
                    this.fp3 = res.data.data.data.p3.value
                    this.conte = res.data.data.data.p4.value
                    this.mycharts()
                }else{
                    this.$message.error(res.data.message)
                }
            }).catch(err=>{

            })
        },
        dk_Dt(val){
            this.imgList = val.image
            this.pageTotalNum = this.imgList.length
            this.innerVisible = true
        },
        // 上一页
        prePage() {
            let page = this.pageNum
            page = page > 1 ? page - 1 : this.pageTotalNum
            this.pageNum = page
        },
        // 下一页
        nextPage() {
            let page = this.pageNum
            page = page < this.pageTotalNum ? page + 1 : 1
            this.pageNum = page
        },
        teamChose(val){
            this.typeTrue = false
            if(this.ruleForm.type!=''){
                this.ruleForm.type=''
            }
        },
            // 获取倒计时
        down(){
            this.axios.StudctdGet({
                token:this.$store.state.token,
                id:this.$store.state.cla_id
            }).then(res=>{
                if(res.data.code==0){
                    if(res.data.data!='' && res.data.data!=null && res.data.data!='null'){
                        this.countNum = 1
                        this.count = res.data.data
                        this.time = res.data.data.times
                        clearTimeout(this.timeouts)
                        if(this.count.state==1){
                            this.countDown()
                            this.timeoutss = setTimeout(this.down,5000)
                        }else{
                            let h = parseInt(this.time / (60 * 60) % 24);
                            h = h < 10 ? "0" + h : h
                            let m = parseInt(this.time / 60 % 60);
                            m = m < 10 ? "0" + m : m
                            let s = parseInt(this.time % 60);
                            s = s < 10 ? "0" + s : s
                            this.timer =  m +':'+ s
                            this.timeoutss = setTimeout(this.down,5000)
                        }
                    }else{
                        this.timeout = setTimeout(this.down,3000)
                        this.countNum = 2
                    }
                }else if(res.data.code==-4054){
                    this.timeout = setTimeout(this.down,3000)
                    this.countNum = 2
                }else{
                  this.countNum = 2
                }
            }).catch(err=>{

            })
        },
        //倒计时
        countDown(){
            if(this.time>0){
                let h = parseInt(this.time / (60 * 60) % 24);
                h = h < 10 ? "0" + h : h
                let m = parseInt(this.time / 60 % 60);
                m = m < 10 ? "0" + m : m
                let s = parseInt(this.time % 60);
                s = s < 10 ? "0" + s : s
                this.timer =  m +':'+ s
                this.time--
                this.timeouts = setTimeout(this.countDown,1000)
                // setTimeout(this.down,5000)
            }else{
                this.timer = '00' +':'+ '00' +':'+ '00'
            }
        },
        toupiao(num){
            if(num==0){
                this.a_show = true
            }else{
                this.a_show = false
            }
            this.axios.StuCprojectgets8({
                token:this.$store.state.token,
                id:this.$store.state.cla_id
            }).then(res=>{
                if(res.data.code==0){
                    var numList = []
                    res.data.data.data.team.values.map(item=>{
                        if(item.id!=this.$store.state.team_id){
                            numList.push(item)
                        }
                    })
                    this.arrList = numList
                    this.activeName = this.arrList[0].name
                    this.dialogVisible = true
                }else{
                    this.$message.error(res.data.message)
                }
            }).catch(err=>{

            })
        },
        shuru(val){
            this.valnums = val
            if(val==1){
                this.mytitle = '最明确的'
            }else if(val==2){
                this.mytitle = '最新想到的'
            }
            this.dialogVisibleForm = true
        },
        handleClick(val){
            console.log(val)
        },
        subxit(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.axios.StuCprojectsets8({
                        token:this.$store.state.token,
                        id:this.$store.state.cla_id,
                        node:'p3',
                        type:this.ruleForm.type==1?'cyxy':this.ruleForm.type==2?'gnsy':'kxxg',
                        value:this.ruleForm.id
                    }).then(res=>{
                        if(res.data.code==0){
                            this.getapp()
                            this.dialogVisible = false
                            this.$refs[formName].resetFields();
                            this.typeTrue = true
                        }else{
                            this.$message.error(res.data.message)
                        }
                    }).catch(err=>{

                    })
                }else{
                    return false
                }
            })
        },
        subxits(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.$confirm('只可以提交一次，提交后不可进行修改！', '确定提交', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning',
                        closeOnClickModal:false
                    }).then(() => {
                        this.axios.StuCprojectsets8({
                            token:this.$store.state.token,
                            id:this.$store.state.cla_id,
                            node:'p4',
                            type:this.valnums==1?'zmqd':'zxxd',
                            value:this.Form.name
                        }).then(res=>{
                            if(res.data.code==0){
                                this.getapp()
                                this.dialogVisibleForm = false
                                this.$refs[formName].resetFields();
                            }else{
                                this.$message.error(res.data.message)
                            }
                        }).catch(err=>{

                        })
                    }).catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消'
                        });          
                    });
                }else{
                    return false
                }
            })
        },
        mycharts(){
            let myChart =echarts.init(this.$refs.charts,"macarons");
            myChart.setOption(
                {
                    color:['#54AAD8','#FFD25B','#FF805B'],
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow',
                            crossStyle: {
                                color: '#999'
                            }
                        },
                        position: function(pt) { //提示框的位置
                        return [pt[0], 30];
                        }
                    },
                     grid: { //图表和父盒子之间的距离
                        show: true,
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        top:'17%',
                        containLabel: true
                    },
                    legend: {//图例
                        top:'5%',
                        data: [
                            {
                                name:'创意新颖',
                                textStyle: {
                                    color: '#333',
                                    fontSize:14
                                }
                            },
                            {
                                name: '功能实用',
                                textStyle: {
                                    color: '#333',
                                    fontSize:14
                                }
                            },
                            {
                                name: '可行性高',
                                textStyle: {
                                    color: '#333',
                                    fontSize:14
                                }
                            }
                        ]
                    },
                    xAxis:{ //x轴
                            type: 'category',
                            // boundaryGap: false,
                            data: this.valuList.xAxis,
                            axisLabel: { 
                                interval:0,
                                textStyle: {
                                color: '#333',
                                    fontSize :10
                                },
                                margin:8
                            },
                            axisLine:{
                                show :true,
                                // symbol: ['none', 'arrow'],
                                lineStyle:{
                                    color:'rgb(2,121,253)'
                                }
                            },
                            axisTick:{
                                show :false
                            },
                            splitLine:{
                                show:false
                            }
                        },
                    yAxis:{
                            type: 'value',
                            axisLabel: { //x轴的坐标文字
                                show: true,
                                textStyle: {
                                    color: '#333' //文字的颜色
                                }
                            },
                            max: function (value) {
                                return Math.ceil(value.max + value.max * 0.1);
                            },
                            axisLine:{
                                show :true,
                                lineStyle:{
                                    color:'rgb(2,121,253)'
                                }
                            },
                            axisTick:{
                                show :false
                            },
                            splitLine:{  //坐标在grid区域的分割线
                            　 lineStyle: { //设置分割线的样式(图表横线颜色)
                                    color: ['#153a8a']
                                }
                            }
                    },
                    series: [
                        {
                            name: '创意新颖',
                            type: 'bar', //柱状图
                            data: this.valuList.cyxy,
                            barWidth :'10%', //柱状体的宽度
                            itemStyle:{
                                normal:{
                                    label:{
                                        show:true,
                                        position:'top',
                                        textStyle:{
                                            color:'#333',
                                            fontSize:14
                                        }
                                    }
                                }
                            }
                        },
                        {
                            name: '功能实用',
                            type: 'bar',
                            data: this.valuList.gnsy,
                            barWidth :'10%',
                            itemStyle:{
                                normal:{
                                    label:{
                                        show:true,
                                        position:'top',
                                        textStyle:{
                                            color:'#333',
                                            fontSize:14
                                        }
                                    }
                                }
                            }  
                        },
                        {
                            name: '可行性高',
                            type: 'bar',
                            data: this.valuList.kxxg,
                            barWidth :'10%',
                            itemStyle:{
                                normal:{
                                    label:{
                                        show:true,
                                        position:'top',
                                        textStyle:{
                                            color:'#333',
                                            fontSize:14
                                        }
                                    }
                                }
                            }
                        }
                    ]
                }
            )
            //让图表自适应
            window.addEventListener("resize",function(){
                myChart.resize()  // myChart 是实例对象
            })
        },
        // 关闭弹窗
        closei(formName){
            this.dialogVisible = false,
            this.dialogVisibleForm = false,
            this.$refs[formName].resetFields();
            this.typeTrue = true
        },
        closeis(){
            this.innerVisible = false
        },
        handleClose(done){

        },// 提交
        submit(){
            this.axios.submits8({
                token:this.$store.state.token,
                id:this.$store.state.cla_id,
            }).then(res=>{
                if(res.data.code==0){
                    this.getapp()
                    this.$message.success('提交成功')
                }else{
                    this.$message.error(res.data.message)
                }
            }).catch(err=>{

            })
        },
    },
    beforeDestroy () {
            let that = this;
            clearTimeout(that.timeout);
            clearTimeout(that.timeouts);
            clearTimeout(that.timeoutss)
            clearInterval(that.scont);
            that.scont = null;
            that.timeout = null;
            that.timeouts = null;
            that.timeoutss = null
    },
    destroyed () {
        let that = this;
        clearTimeout(that.timeout);
        clearTimeout(that.timeouts);
        clearInterval(that.scont);
        clearTimeout(that.timeoutss)
        that.scont = null;
        that.timeout = null;
        that.timeouts = null;
        that.timeoutss = null
    },
    mounted() {
        this.getapp()
        this.down()
        let that = this;
        that.scont = window.setInterval(() => {
            setTimeout(() => {
                that.getapp()
            }, 0)
        },5000)
    }
}
</script>
<style scoped>
@import './../../assets/css/student/Feamization.css';
</style>
<style>
.form_image .el-carousel{
    width: 100%;
    height: 100%;
}
.form_image .el-carousel__container{
    height: 100%;
}
.form_image .el-carousel__arrow{
    display: none!important;
}
.form_image .el-carousel__arrow:hover{
    display: none!important;
}
.form_image .el-carousel__indicators{
    display: none;
}
.Feam_popup .el-tabs--border-card{
    box-shadow: none;
}
.Feam_popup .demo_dw{
    position: relative;
    padding-left: 30px;
    box-sizing: border-box;
}
.demo_dw_div{
    position:absolute;
    top: 0;
    right: 35px;
}
.Feam_popup .el-dialog{
    margin-top: 8vh!important;
}
.Feam_popup .el-dialog__body{
    padding: 20px 0px 10px;
}
.Feam_popup .el-dialog__header{
    padding-top: 10px;
    text-align: center;
    border-bottom: 1px solid #DCDFE6;
    background: #4DA3D2;
}
.Feam_popup .el-dialog__title{
    font-size: 16px;
    font-weight: bold;
    color:#fff;
}
.Feam_popup .el-form-item__label{
    font-weight: bold;
    color: #333;
    text-align: right;
    float: none;
    display: inline-block;
        vertical-align: top;
}
.Feam_popup .el-form-item__content{
    margin-left: 0!important;
    text-align: center;
    display: inline-block;
}
.Feam_popup .el-form-item--small.el-form-item{
    margin-bottom: 22px;
    text-align: center;
}
.Feam_popup .left_div_f .el-form-item--small.el-form-item{
    text-align: left;
}
.Feam_popup .el-dialog__footer{
    text-align: center;
}
.Feam_popup .left_div_f .el-textarea__inner{
    width: 370px;
}
.Feam_popup .left_div_t .el-input__inner{
    width: 370px;
}
.Feam_popup .form_div .el-textarea__inner{
    width: 350px;
}
.Feam_popup .el-textarea__inner{
    width: 500px;
}
.Feam_popup .el-button--mini{
    width: 80px;
}
.Feam_popup .el-descriptions-item__label.is-bordered-label{
    width: 18%;
}
</style>